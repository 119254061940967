import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl } from '@angular/forms';
import { FormlyFieldConfigCache, FormlyFieldConfig } from '@ngx-formly/core/lib/models';
import { isObservable } from 'rxjs';

export function unregisterControl(field: FormlyFieldConfig, emitEvent = false) {
  const form = field.formControl.parent as UntypedFormArray | UntypedFormGroup;

  if (!form) {
    return;
  }

  const control = field.formControl;
  const opts = { emitEvent };

  if (form instanceof UntypedFormArray) {
    const key = form.controls.findIndex(c => c === control);

    if (key !== -1) {
      updateControl(form, opts, () => form.removeAt(key));
    }
  } else if (form instanceof UntypedFormGroup) {
    const paths = getKeyPath(field);
    const key = paths[paths.length - 1];
    if (form.get([key]) === control) {
      updateControl(form, opts, () => form.removeControl(key));
    }
  }

  control.setParent(null);
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (field['autoClear']) {
    if (field.parent.model) {
      delete field.parent.model[field.key as string];
    }

    control.reset(
      { value: undefined, disabled: control.disabled },
      { emitEvent: field.fieldGroup ? false : emitEvent, onlySelf: true }
    );
  }
}

export function registerControl(field: FormlyFieldConfigCache, control?: any, emitEvent = false) {
  control = control || field.formControl;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (!control['_fields']) {
    defineHiddenProp(control, '_fields', []);
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (control['_fields'].indexOf(field) === -1) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    control['_fields'].push(field);
  }

  if (!field.formControl && control) {
    defineHiddenProp(field, 'formControl', control);

    field.props.disabled = !!field.props.disabled;
    wrapProperty(field.props, 'disabled', ({ firstChange, currentValue }) => {
      if (!firstChange) {
        currentValue ? field.formControl.disable() : field.formControl.enable();
      }
    });

    if (control.registerOnDisabledChange) {
      control.registerOnDisabledChange(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        (val: boolean) => field.props['___$disabled'] = val,
      );
    }
  }

  let parent = field.parent.formControl as UntypedFormGroup;

  if (!parent) {
    return;
  }

  const paths = getKeyPath(field);

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (!parent['_formlyControls']) {
    defineHiddenProp(parent, '_formlyControls', {});
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  parent['_formlyControls'][paths.join('.')] = control;

  for (let i = 0; i < (paths.length - 1); i++) {
    const path = paths[i];

    if (!parent.get([path])) {
      registerControl({
        key: path,
        formControl: new UntypedFormGroup({}),
        parent: { formControl: parent },
      });
    }

    parent = parent.get([path]) as UntypedFormGroup;
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (field['autoClear'] && !isUndefined(field.defaultValue) && isUndefined(getFieldValue(field))) {
    assignModelValue(field.parent.model, getKeyPath(field), field.defaultValue);
  }

  const value = getFieldValue(field);

  if (
    !(isNullOrUndefined(control.value) && isNullOrUndefined(value))
    && control.value !== value
    && control instanceof UntypedFormControl
  ) {
    control.patchValue(value);
  }

  const key = paths[paths.length - 1];

  if (!field._hide && parent.get([key]) !== control) {
    updateControl(
      parent,
      { emitEvent },
      () => parent.setControl(key, control),
    );
  }
}

export function wrapProperty<T = any>(
  o: any,
  prop: string,
  setFn: (change: {currentValue: T, previousValue?: T, firstChange: boolean}) => void,
) {
  if (!o._observers) {
    defineHiddenProp(o, '_observers', {});
  }

  if (!o._observers[prop]) {
    o._observers[prop] = [];
  }

  const fns: typeof setFn[] = o._observers[prop];

  if (fns.indexOf(setFn) === -1) {
    fns.push(setFn);
    setFn({ currentValue: o[prop], firstChange: true });

    if (fns.length === 1) {
      defineHiddenProp(o, `___$${prop}`, o[prop]);
      Object.defineProperty(o, prop, {
        configurable: true,
        get: () => o[`___$${prop}`],
        set: currentValue => {
          if (currentValue !== o[`___$${prop}`]) {
            const previousValue = o[`___$${prop}`];
            o[`___$${prop}`] = currentValue;
            fns.forEach(changeFn => changeFn({ previousValue, currentValue, firstChange: false }));
          }
        },
      });
    }
  }

  return () => fns.splice(fns.indexOf(setFn), 1);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function updateControl(form: UntypedFormGroup|UntypedFormArray, opts: { emitEvent: boolean }, action: Function) {
  /**
   *  workaround for https://github.com/angular/angular/issues/27679
   */
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (form instanceof UntypedFormGroup && !form['__patchForEachChild']) {
    defineHiddenProp(form, '__patchForEachChild', true);
    // eslint-disable-next-line @typescript-eslint/ban-types
    (form as any)._forEachChild = (cb: Function) => {
      Object
        .keys(form.controls)
        .forEach(k => form.controls[k] && cb(form.controls[k], k));
    };
  }

  /**
   * workaround for https://github.com/angular/angular/issues/20439
   */
  const updateValueAndValidity = form.updateValueAndValidity.bind(form);

  if (opts.emitEvent === false) {
    form.updateValueAndValidity = (options) => {
      updateValueAndValidity({ ...(options || {}), emitEvent: false });
    };
  }

  action();

  if (opts.emitEvent === false) {
    form.updateValueAndValidity = updateValueAndValidity;
  }
}

export function getKeyPath(field: FormlyFieldConfigCache): string[] {
  if (!field.key) {
    return [];
  }

  /* We store the keyPath in the field for performance reasons. This function will be called frequently. */
  if (!field._keyPath || field._keyPath.key !== field.key) {
    let path: string[] = [];
    if (typeof field.key === 'string') {
      const key = field.key.indexOf('[') === -1
        ? field.key
        : field.key.replace(/\[(\w+)\]/g, '.$1');
      path = key.indexOf('.') !== -1 ? key.split('.') : [key];
    } else if (Array.isArray(field.key)) {
      path = (field.key as any[]).slice(0);
    } else {
      path = [`${field.key}`];
    }

    field._keyPath = { key: field.key, path };
  }

  return field._keyPath.path.slice(0);
}

// export function getKeyPath(field: FormlyFieldConfigCache): string[] {
//   if (!field.key) {
//     return [];
//   }

//   /* We store the keyPath in the field for performance reasons. This function will be called frequently. */
//   if (!field._keyPath || field._keyPath.key !== field.key) {
//     const key = field.key.indexOf('[') === -1
//       ? field.key
//       : field.key.replace(/\[(\w+)\]/g, '.$1');

//     field._keyPath = { key: field.key, path: key.indexOf('.') !== -1 ? key.split('.') : [key] };
//   }

//   return field._keyPath.path.slice(0);
// }

export function isNullOrUndefined(value: any) {
  return value === undefined || value === null;
}

export function clone(value: any): any {
  if (
    !isObject(value)
    || isObservable(value)
    || /* instanceof SafeHtmlImpl */ value.changingThisBreaksApplicationSecurity
    || ['RegExp', 'FileList', 'File', 'Blob'].indexOf(value.constructor.name) !== -1
  ) {
    return value;
  }

  // https://github.com/moment/moment/blob/master/moment.js#L252
  if (value._isAMomentObject && isFunction(value.clone)) {
    return value.clone();
  }

  if (value instanceof AbstractControl) {
    return null;
  }

  if (value instanceof Date) {
    return new Date(value.getTime());
  }

  if (Array.isArray(value)) {
    return value.slice(0).map(v => clone(v));
  }

  // best way to clone a js object maybe
  // https://stackoverflow.com/questions/41474986/how-to-clone-a-javascript-es6-class-instance
  const proto = Object.getPrototypeOf(value);
  let c = Object.create(proto);
  c = Object.setPrototypeOf(c, proto);
  // need to make a deep copy so we dont use Object.assign
  // also Object.assign wont copy property descriptor exactly
  return Object.keys(value).reduce((newVal, prop) => {
    const propDesc = Object.getOwnPropertyDescriptor(value, prop);

    if (propDesc.get) {
      Object.defineProperty(newVal, prop, propDesc);
    } else {
      newVal[prop] = clone(value[prop]);
    }

    return newVal;
  }, c);
}

export function defineHiddenProp(field: any, prop: string, defaultValue: any) {
  Object.defineProperty(field, prop, { enumerable: false, writable: true, configurable: true });
  field[prop] = defaultValue;
}

export function isFunction(value: any) {
  return typeof(value) === 'function';
}

export function isObject(x: any) {
  return x != null && typeof x === 'object';
}

export function assignModelValue(model: any, paths: string[], value: any) {
  for (let i = 0; i < (paths.length - 1); i++) {
    const path = paths[i];

    if (!model[path] || !isObject(model[path])) {
      model[path] = /^\d+$/.test(paths[i + 1]) ? [] : {};
    }

    model = model[path];
  }

  model[paths[paths.length - 1]] = clone(value);
}

export function isUndefined(value: any) {
  return value === undefined;
}

export function getFieldValue(field: FormlyFieldConfig): any {
  let model = field.parent.model;

  for (const path of getKeyPath(field)) {
    if (!model) {
      return model;
    }
    model = model[path];
  }

  return model;
}
